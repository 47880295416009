import React from 'react';

import withHover from './hover';

import { TitleColour } from '../../utils/variables';
import { buttonStyles, buttonLoading, buttonInactive } from './style';

import './button.css';

export default withHover(function SubmitButton({
  children,
  primary,
  secondary,
  dark,
  style,
  className,
  label,
  processing,
  disabled,
  toggleHover,
  hoverColour,
  hover,
  ...other
}) {
  const loadingStyles = processing && buttonLoading;
  const inactiveStyles = disabled && buttonInactive;

  const primaryStyle = primary && {
    backgroundColor: hoverColour(dark),
    color: `#fff`,
  };

  const secondaryText = () => {
    if (dark) {
      return !hover ? TitleColour : `#fff`;
    }
    return !hover ? `#fff` : TitleColour;
  };

  const secondaryStyle = secondary && {
    border: `2px solid ${dark ? TitleColour : `#fff`}`,
    color: secondaryText(),
    backgroundColor: hoverColour(),
  };

  return (
    <button
      type="submit"
      className={`btn${className ? ` ${className}` : ``}`}
      style={{
        ...buttonStyles,
        ...loadingStyles,
        ...inactiveStyles,
        ...primaryStyle,
        ...secondaryStyle,
        ...style,
        // color: primary ? `#fff` : `#383838`,
        // backgroundColor: hoverColour(),
      }}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      aria-label={
        label ||
        (typeof children === 'object' ? children.props.children : children)
      }
      disabled={disabled}
      {...other}
    >
      {children}
    </button>
  );
});
